<template>
  <base-layout>
    <SunpodAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            :title="$t('components.pods.sunpods.headline')"
            @plus="add"
            :hide-plus="false"
          />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="getTableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem
                    :text="item.id"
                    :truncate="-5"
                    :to="{ name: 'ViewSunpodProfile', params: { id: item.id } }"
                  />
                  <FSTableRowItem :text="item.name" />
                  <FSTableRowItem :text="item.instance" />

                  <FSTableRowItem
                    :text="
                      item.assigned_edgebox ? item.assigned_edgebox.name : '--'
                    "
                  />
                  <FSTableRowItem>
                    <HeartbeatStatus :status="item.is_active" />
                  </FSTableRowItem>
                  <FSTableRowItem class="flex items-center">
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :isDoorOpen="
                        item.assigned_edgebox
                          ? item.assigned_edgebox.is_door_open
                          : false
                      "
                      :sunpodId="item.id"
                      @edit="edit(item)"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="item.name"
                    :to="{ name: 'ViewSunpodProfile', params: { id: item.id } }"
                  />
                  <FSTableRowItem>
                    <HeartbeatStatus :status="item.is_active" />
                  </FSTableRowItem>
                  <FSTableRowItem class="flex items-center">
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :isDoorOpen="
                        item.assigned_edgebox
                          ? item.assigned_edgebox.is_door_open
                          : false
                      "
                      :sunpodId="item.id"
                      @edit="edit(item)"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('components.pods.sunpods.table.columns.id') }}
                      </div>
                      <div class="col-span-5 right-text">
                        #{{ item.id | onlyLastFive }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.pods.sunpods.table.columns.instance')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.instance }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.pods.sunpods.table.columns.assignedEdgebox'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{
                          item.assigned_edgebox
                            ? item.assigned_edgebox.name
                            : '--'
                        }}
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import BaseLayout from '@/views/shared/BaseLayout.vue'

import SunpodAddEdit from '@/views/sunpods/SunpodAddEdit'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { SunpodsConfig } from '@/config/SunpodsConfig'
import { EventBus } from '@/utils'
export default {
  name: 'SunpodIndex',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    SunpodAddEdit,
    MoreActionsDropdown: () =>
      import('@/views/sunpods/MoreActionsDropdown.vue'),
    HeartbeatStatus: () => import('@/components/badge/HeartbeatStatus'),
  },
  data() {
    return {
      fstId: 'SunpodIndex',
      indexDataEndpoint: SunpodsConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t('components.pods.sunpods.table.columns.id'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.pods.sunpods.table.columns.name'),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t('components.pods.sunpods.table.columns.instance'),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.table.columns.assignedEdgebox'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t('components.pods.sunpods.table.columns.status'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.pods.sunpods.table.columns.actions'),
          width: '15%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.pods.sunpods.table.columns.name'),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t('components.pods.sunpods.table.columns.status'),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.pods.sunpods.table.columns.actions'),
          width: '40%',
          sort: null,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(data) {
      if (data) return 'green'
      else return 'gray'
    },
    add: function() {
      EventBus.$emit(SunpodsConfig.events.editingData, {})
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(SunpodsConfig.events.editingData, {
        ...item,
        assigned_edgebox: item.assigned_edgebox.id,
        sun_pod_area: item.sun_pod_area ? item.sun_pod_area.id : null,
      })
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
