export const EdgeboxConfig = {
  api: {
    // get
    index: '/dashboard/edgeboxes/',
    // post
    create: '/dashboard/edgeboxes/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/edgeboxes/${id}/`
    },
    detail: (id = 'uuid') => {
      return `/dashboard/edgeboxes/${id}/`
    },
  },
  events: {
    name: 'edgebox',
    // refresh-index-data
    refresh: `rid-edgebox`,
    // slideover-right
    sorId: 'edgebox',
    sorOpen: 'sor-open-edgebox',
    sorClose: 'sor-close-edgebox',
    sorToggle: 'sor-toggle-edgebox',
    // editing-data
    editingData: 'edit-edgebox-data',
    // viewing-data
    viewingData: 'view-edgebox-data',
  },
}
