var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('div',[_c('TitlePlus',{attrs:{"title":"IOT Management","hide-plus":true}})],1),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)]),_c('div',{staticClass:"summary-card-container"},[_c('SummaryCard',{attrs:{"title":"Number of Locks","value":_vm.indexMetaData.count.total,"variant":"gray","size":"large"}}),_c('SummaryCard',{attrs:{"title":"Assigned Locks","value":_vm.indexMetaData.summary.assigned,"variant":"gray","size":"large"}}),_c('SummaryCard',{attrs:{"title":"Unassigned Locks","value":_vm.indexMetaData.summary.unassigned,"variant":"gray","size":"large"}}),_c('SummaryCard',{attrs:{"title":"Damaged Locks","value":_vm.indexMetaData.summary.damaged,"variant":"gray","size":"large"}})],1)]),_c('FSTable',{attrs:{"fst-id":_vm.fstId,"headers":_vm.getTableHeaders,"endpoint":_vm.endpoint},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5}}),_c('FSTableRowItem',{attrs:{"to":{
                  name: 'ViewVehicleProfile',
                  params: { id: item.vehicle_id },
                },"text":item.name}}),_c('FSTableRowItem',{attrs:{"text":item.vehicle_id}}),_c('FSTableRowItem',[_c('battery-status',{attrs:{"level":item.power_level}})],1),_c('FSTableRowItem',{attrs:{"text":item.lock_id}}),_c('FSTableRowItem',{attrs:{"text":item.sim_number}}),_c('FSTableRowItem',[_c('div',{staticClass:"pl-10"},[_c('i',{class:[
                      item.is_operational
                        ? 'fas fa-exclamation-circle text-red-500'
                        : 'fas fa-check-circle text-green-500' ]})])]),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_locked ? 'green' : 'orange',"text":item.is_locked ? 'Locked' : 'Unlocked',"profile":"trip"}})],1)],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"to":{
                  name: 'ViewVehicleProfile',
                  params: { id: item.vehicle_id },
                },"text":item.name}}),_c('FSTableRowItem',[_c('div',{staticClass:"pl-10"},[_c('i',{class:[
                      item.is_operational
                        ? 'fas fa-exclamation-circle text-red-500'
                        : 'fas fa-check-circle text-green-500' ]})])]),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_locked ? 'green' : 'orange',"text":item.is_locked ? 'Locked' : 'Unlocked',"profile":"trip"}})],1)],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Id")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Assigned Vehicle")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.vehicle_id)+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Battery Charge")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('battery-status',{attrs:{"level":item.power_level}})],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("IMEI Code")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(_vm._s(item.lock_id))])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("SIM Number")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.sim_number)+" ")])])])]):_vm._e()]:_vm._e()]})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }