<template>
  <base-layout>
    <EdgeboxAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            :title="$t('components.pods.edgebox.headline')"
            @plus="add"
            :hide-plus="false"
          />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="getTableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem :text="item.id" :truncate="-5" />
                  <FSTableRowItem :text="item.name" />
                  <FSTableRowItem :text="item.imei" />
                  <FSTableRowItem :text="item.mac_address" />
                  <FSTableRowItem>
                    <OtoEditIcon @click="edit(item)" />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.name" />
                  <FSTableRowItem :text="item.imei" />
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('components.pods.edgebox.table.columns.id') }}
                      </div>
                      <div class="col-span-5 right-text">
                        #{{ item.id | onlyLastFive }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.pods.edgebox.table.columns.macAddress')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.mac_address || '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.pods.edgebox.table.columns.actions')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <OtoEditIcon @click="edit(item)" />
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import EdgeboxAddEdit from '@/views/sunpods/EdgeboxAddEdit.vue'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'

import { EdgeboxConfig } from '@/config/EdgeboxConfig'
import { EventBus } from '@/utils'
export default {
  name: 'EdgeboxIndex',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    EdgeboxAddEdit,
  },
  data() {
    return {
      fstId: 'EdgeboxIndex',
      indexDataEndpoint: EdgeboxConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t('components.pods.edgebox.table.columns.id'),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.pods.edgebox.table.columns.name'),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t('components.pods.edgebox.table.columns.imei'),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.pods.edgebox.table.columns.macAddress'),
          width: '25%',
          sort: null,
        },
        {
          text: this.$t('components.pods.edgebox.table.columns.actions'),
          width: '5%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.pods.edgebox.table.columns.name'),
          width: '40%',
          sort: null,
        },
        {
          text: this.$t('components.pods.edgebox.table.columns.imei'),
          width: '50%',
          sort: null,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add: function() {
      EventBus.$emit(EdgeboxConfig.events.editingData, {})
      dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(EdgeboxConfig.events.editingData, item)
      dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
